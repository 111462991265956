import { Option } from '../components/molecules/Invoices/InvoiceCardLinkBoard'
import { CartItem, InvoiceSummary, Product, Rating } from '../store/data/types'
import { MerchantStatus } from '../store/slices/session'
import {
  DELIVERY_TYPES,
  DELIVERY_TYPE_ENUM,
  PRICING_GROUP_IDS_IN_GET_MORE_FOR_LESS,
  ZENDESK_HELP_LINK,
  blockingStatuses,
  whatsappHelpLinkGenerator,
} from './constants'

export const cpfMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{5})\d+?$/, '$1') // captura 5 numeros e não deixa ser digitado mais nada
}

export const formatDateDayMonth = (date?: string): string => {
  if (!date) {
    return ''
  }
  return date.slice(8) + '/' + date.slice(5, 7)
}

export const weekDayMapping: { [index: number]: string } = {
  0: 'domingo',
  1: 'segunda-feira',
  2: 'terça-feira',
  3: 'quarta-feira',
  4: 'quinta-feira',
  5: 'sexta-feira',
  6: 'sábado',
}

export const checkDelayedPayment = (billingStatus: string) => {
  return billingStatus === 'OVERDUE' || billingStatus === 'DUNNING_REQUESTED'
}

export const filterInvoicesByBillingStatus = (invoices: InvoiceSummary[], getOverdue: boolean) => {
  return invoices.filter(({ billingStatus }) => checkDelayedPayment(billingStatus) === getOverdue)
}

export const isMerchantBlocked = (status: MerchantStatus | undefined) =>
  blockingStatuses.some((blockingStatus) => status === blockingStatus)

export const proveSourceWebhook = async (data: any) => {
  const messageUrl = 'https://api.provesrc.com/webhooks/track/98dc2ef1601542ed48e6721267947a65'
  const response = await fetch(messageUrl, {
    method: 'POST',
    body: JSON.stringify(data),
  })
  return response.json()
}

export const statusTranslator = (status: string) => {
  switch (status) {
    case 'DELIVERY_ACCEPTED':
      return 'Entrega realizada'
    case 'RECEIVED':
      return 'Em preparação'
    case 'PICKING':
      return 'Em separação'
    case 'PICKED':
      return 'Esperando coleta do entregador'
    case 'ROUTE':
      return 'Em rota de entrega'
    case 'DELIVERY_NOT_ACCEPTED':
      return 'Entrega não realizada'
    default:
      return 'Em preparação'
  }
}

export const paymentTypeTranslator = (paymentType: number) => {
  switch (paymentType) {
    case 0:
      return 'Boleto'
    case 1:
      return 'Cartão de crédito'
    case 2:
      return 'Pix'
    case 3:
      return 'Transferência'
    case 4:
      return 'Depósito'
    default:
      return 'unknown'
  }
}

export const billingStatusTranslator = (status: string) => {
  switch (status) {
    case 'PENDING':
      return 'Aguardando pagamento'
    case 'RECEIVED':
      return 'Pagamento confirmado'
    case 'CONFIRMED':
      return 'Pagamento confirmado'
    case 'OVERDUE':
      return 'Pagamento atrasado'
    case 'REFUNDED':
      return 'Pagamento estornado'
    default:
      return 'Aguardando geração do boleto'
  }
}

export const dateFromTimestamp = (dateString: string) => {
  const weekdayMapping: { [index: number]: string } = {
    0: 'Domingo',
    1: 'Segunda-feira',
    2: 'Terça-feira',
    3: 'Quarta-feira',
    4: 'Quinta-feira',
    5: 'Sexta-feira',
    6: 'Sábado',
  }
  const dateObject = new Date(dateString)
  const weekday = dateObject.getDay()
  const day = dateObject.getDate()
  const month = dateObject.getMonth()
  const year = dateObject.getFullYear()

  return `${weekdayMapping[weekday]}, ${day}/${month + 1}/${year}`
}

export const groupInvoicesByDate = (invoices: InvoiceSummary[]) => {
  const groupedInvoices: { [index: string]: InvoiceSummary[] } = {}

  invoices.forEach((invoice) => {
    const timestamp = Date.parse(invoice.creationDatetime)
    const dateObject = new Date(timestamp)
    dateObject.setHours(0, 0, 0, 0)
    const dateString = dateObject.toISOString()
    if (groupedInvoices[dateString]) {
      groupedInvoices[dateString].push(invoice)
    } else {
      groupedInvoices[dateString] = [invoice]
    }
  })

  return groupedInvoices
}

export const checkString = (url?: string | null) => url === '' || url === null || url === undefined

export const generateLinkOptionsFromInvoice = (invoice: InvoiceSummary, merchantCode?: string) => {
  const { shopperPlanId, billingStatus, url, leftForDeliveryDatetime, billingUrl, nfeUrls } =
    invoice
  const subOptions: Option[] =
    nfeUrls.length > 0
      ? nfeUrls.map((url) => ({
          label: url.includes('amazon') ? 'Nota Fiscal' : 'Cupom Fiscal',
          url,
          disabled: false,
          subOptions: [],
        }))
      : []

  const options: Option[] = [
    { label: 'Pedido', url: `/purchase${url}`, disabled: checkString(url), subOptions: [] },
    {
      label: 'Fatura',
      url: leftForDeliveryDatetime ? `/delivery${url}` : leftForDeliveryDatetime,
      disabled: checkString(url) || checkString(leftForDeliveryDatetime),
      subOptions: [],
    },
    { label: 'Pagamento', url: billingUrl, disabled: checkString(billingUrl), subOptions: [] },
    { label: 'Doc. Fiscal', subOptions: subOptions, disabled: nfeUrls.length <= 0 },
    {
      label: 'Ajuda',
      url: checkDelayedPayment(billingStatus)
        ? whatsappHelpLinkGenerator(merchantCode, shopperPlanId)
        : ZENDESK_HELP_LINK,
      disabled: false,
      subOptions: [],
    },
  ]

  return options
}

const DEADLINE_TO_EVALUATE_DELIVERY = 60 // 60 days

export const isRatingDisabled = (rating: Rating) => {
  const creationDate = new Date(rating.creationDatetime)
  const currentDate = new Date()
  const timeDifference = currentDate.getTime() - creationDate.getTime()
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24)

  return daysDifference > DEADLINE_TO_EVALUATE_DELIVERY
}

const debouncePromise = (fn: any, time: any) => {
  let timerId: any = undefined

  return (...args: any[]) => {
    if (timerId) {
      clearTimeout(timerId)
    }

    return new Promise((resolve) => {
      timerId = setTimeout(() => resolve(fn(...args)), time)
    })
  }
}

export const debounced = debouncePromise((items: any) => Promise.resolve(items), 500)

export const pad = (num: number, size: number): string => {
  let numStr: string = num.toString()
  while (numStr.length < size) numStr = '0' + numStr
  return numStr
}

export const getDeadlineMessage = (paymentDeadline: string | null) =>
  `Seu prazo para pagar é de ${paymentDeadline} dias após o recebimento.`

export const getShippingInfos = (type: DELIVERY_TYPE_ENUM) => {
  if (type === DELIVERY_TYPE_ENUM.free) {
    return DELIVERY_TYPES.free
  }
  return DELIVERY_TYPES.fast
}

export const stringToArrayEan = (list: string) => {
  const dataArray = list.split(/\n/)
  const eansArray = dataArray.map((data) => {
    if (data.includes('http')) {
      const ean = data.split('/').pop()

      if (ean) return ean
    }

    return data
  })

  return eansArray
}

export const currentDateString = new Date().toLocaleString('pt-BR', {
  timeZone: 'America/Sao_Paulo',
})

export const getDayOfWeek = (date: Date) => {
  const days = [
    'Domingo',
    'Segunda-feira',
    'Terça-feira',
    'Quarta-feira',
    'Quinta-feira',
    'Sexta-feira',
    'Sábado',
  ]
  return days[date.getDay()]
}

export const getLongDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: '2-digit' }
  return date.toLocaleDateString('pt-BR', options)
}

export const sortProducts = (products: Product[], eans: string[]) => {
  products.sort((a, b) => {
    const indexA = eans.indexOf(a.ean)
    const indexB = eans.indexOf(b.ean)

    return indexA - indexB
  })

  return products
}

export const showPrices = (merchantCode: string | undefined) => {
  return !!merchantCode
}

export const getEconomy = (item: CartItem) => {
  const { getMoreForLess } = item.product.supplierPrices[0]
  if (getMoreForLess) {
    const { priceWithoutPromotion, quantityToGet, promotionalPrice } = getMoreForLess
    const economyCalc = priceWithoutPromotion * quantityToGet - promotionalPrice * quantityToGet
    return economyCalc
  }
  return 0
}

export const quantityToCompleteComboCalc = (item: CartItem | undefined) => {
  const hasGetMoreForLess = item?.product.supplierPrices[0].getMoreForLess
  return hasGetMoreForLess?.quantityToGet ? hasGetMoreForLess.quantityToGet - item!.quantity : 0
}

export const isGetMoreForLess = (
  getMoreForLess: Record<string, unknown> | null,
  pricingGroupId: number | undefined
) => {
  const pricingGroupValid = PRICING_GROUP_IDS_IN_GET_MORE_FOR_LESS.includes(pricingGroupId ?? 0)

  return getMoreForLess !== null && pricingGroupValid
}

export const formatNumber = (value: number): string => {
  if (value) {
    let formattedValue = value.toFixed(2)
    return (formattedValue = formattedValue.replace('.', ','))
  }
  return ''
}

export const isClubbiAnniversary = () => {
  const today = new Date()
  const start = new Date('2024-09-02 07:00:00')
  const end = new Date('2024-10-11 23:59:00')
  return today >= start && today <= end
}

export const anniversaryColor = {
  yellow: '#FFC200',
  deepPurple: '#4600A2',
  red: '#F21F52',
}
